/* eslint-disable react/forbid-prop-types */
import { Layout, Menu } from 'antd';
import intersection from 'lodash/intersection';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import debounce from 'lodash/debounce';
import { themeConfig } from '../../config';
import { getCurrentTheme } from '../ThemeSwitcher/config';

import IntlMessages from '../../components/utility/intlMessages';
import Logo from '../../components/utility/logo';
import { useUserRoles } from '../../hooks';
import appActions from '../../redux/app/actions';

import {
  getApp,
  getCurrent,
  getCurrentView,
  getDrawer,
  getOpenKeys,
  getSelectedBranch,
} from '../../redux/app/selectors';
import { getPermissions } from '../../redux/authority/selectors';
import { getAllActivePlugins } from '../../redux/plugins/selectors';
import menuOptions from './options';
import SidebarWrapper from './sidebar.style';
import { getAllBranches } from '../../redux/branches/selectors';

const { Sider } = Layout;

const getAncestorKeys = key => {
  const map = {
    sub3: ['sub2'],
  };
  return map[key] || [];
};

// Function to resolve current selected item on sidebar
const getCurrentSelectedSideItem = current => {
  if (['customers', 'business-accounts', 'invoices', 'rights', 'logs'].includes(current[0]))
    return ['customers'];
  if (['vehicles', 'tasks', 'damages'].includes(current[0])) return ['vehicles'];
  if (['bookings', 'booking-calendar'].includes(current[0])) return ['bookings'];
  if (['poi', 'poi-categories'].includes(current[0])) return ['poi'];
  return current;
};

const Sidebar = ({ url, changeOpenKeys, current, changeCurrent, toggleCollapsed, permissions }) => {
  /**
   * All active plugins, those that are true will be displayed
   */
  const activePlugins = useSelector(getAllActivePlugins);
  const dispatch = useDispatch();

  const openKeys = useSelector(getOpenKeys);
  const view = useSelector(getCurrentView);
  const openDrawerFromState = useSelector(getDrawer);
  const allBranches = useSelector(getAllBranches);
  const selectedBranch = useSelector(getSelectedBranch);
  const [locationKey, setLocationKey] = useState('');
  /** `userRoles` array of all current user roles */
  const userRoles = useUserRoles();
  const history = useHistory();

  const rootBranch = allBranches.find(branch => branch?.id === 1);

  const onOpenChange = React.useCallback(
    newOpenKeys => {
      const latestOpenKey = newOpenKeys.find(key => !(openKeys.indexOf(key) > -1));
      const latestCloseKey = openKeys.find(key => !(newOpenKeys.indexOf(key) > -1));
      let nextOpenKeys = [];
      if (latestOpenKey) {
        nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
      }
      if (latestCloseKey) {
        nextOpenKeys = getAncestorKeys(latestCloseKey);
      }
      if (isEqual(nextOpenKeys.sort() !== openKeys.sort())) changeOpenKeys(nextOpenKeys);
    },
    [changeOpenKeys, openKeys]
  );
  const handleUrlChanged = React.useCallback(() => {
    const option = menuOptions.find(
      opt => opt.url === window.location.pathname + window.location.search
    );
    const key = current?.[0];
    if (option?.key !== key) {
      changeCurrent([option?.key]);
    }
  }, [current, changeCurrent]);

  React.useEffect(() => {
    const debounceDragTouchpad = debounce(handleUrlChanged, 500);
    window.addEventListener('mousewheel', debounceDragTouchpad);
    return () => {
      window.removeEventListener('mousewheel', debounceDragTouchpad);
    };
  }, [handleUrlChanged]);

  React.useEffect(() => {
    return history.listen(location => {
      if (location.key !== locationKey) {
        setLocationKey(location.key);
        handleUrlChanged();
      }
    });
  }, [history, locationKey, handleUrlChanged]);

  const handleClick = e => {
    if (current[0] !== e.key) {
      changeCurrent([e.key]);
      if (view !== 'DesktopView') {
        toggleCollapsed();
      }
    }
  };

  /**
   * Metod used to handle side bar route accessibility
   * @param {object} option - object with all side bar properties
   */
  const isAccessible = React.useCallback(
    option => {
      const { roles, permission, plugin, onlyRootBranch } = option;
      if (plugin && !activePlugins[plugin]) return false;
      if (permission?.length && !permission.find(p => permissions[p])) return false;
      if (roles && !intersection(option.roles, userRoles).length) return false;
      if (onlyRootBranch && rootBranch?.id !== selectedBranch?.id) return false;
      return true;
    },
    [userRoles, activePlugins, permissions, rootBranch, selectedBranch]
  );

  const customizedTheme = getCurrentTheme('sidebarTheme', themeConfig.theme);
  const collapsed = view === 'DesktopView';
  const openDrawer = cloneDeep(openDrawerFromState); // if Sidebar is open on left side of map, then app.openDrawe is true, else is false
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
    overflow: 'auto',
    height: '100vh',
  };

  return (
    <>
      {(openDrawer || collapsed) && (
        <SidebarWrapper>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className="isomorphicSidebar ant-menu-inline-collapsed"
            style={styling}
          >
            {/* changeCurrent['string'] - what is current selected in sidebare options */}
            <Logo onClick={() => current[0] !== 'map' && changeCurrent(['map'])} />
            <Menu
              onClick={handleClick}
              theme="dark"
              openKeys={collapsed ? [] : openKeys}
              selectedKeys={getCurrentSelectedSideItem(current)}
              onOpenChange={onOpenChange}
              className="isoDashboardMenu"
              items={menuOptions
                .map(option => {
                  const urlLink = `${url}${option.url}`.includes('//')
                    ? `${url}${option.url}`.replace('//', '/')
                    : `${url}${option.url}`;
                  const linkLabel = (
                    <span className="isoMenuHolder">
                      <span className="nav-text">
                        <IntlMessages id={option.label} />
                      </span>
                    </span>
                  );

                  return isAccessible(option)
                    ? {
                        icon: option.leftIcon,
                        key: option.key,
                        label: (
                          <Link
                            to={urlLink}
                            onClick={() => {
                              dispatch(appActions.urlChange(true));
                            }}
                          >
                            {linkLabel}
                          </Link>
                        ),
                      }
                    : null;
                })
                .filter(Boolean)}
            />
          </Sider>
        </SidebarWrapper>
      )}
    </>
  );
};

Sidebar.propTypes = {
  changeOpenKeys: PropTypes.func.isRequired,
  changeCurrent: PropTypes.func.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
  url: PropTypes.string,
  permissions: PropTypes.oneOfType([PropTypes.object]),
  current: PropTypes.array.isRequired,
};

Sidebar.defaultProps = {
  url: '/dashboard',
  permissions: {},
};

const mapStateToProps = state => ({
  app: getApp(state),
  permissions: getPermissions(state),
  current: getCurrent(state),
});

const mapDispatchToProps = dispatch => ({
  toggleCollapsed: () => {
    dispatch(appActions.toggleCollapsed());
  },
  changeOpenKeys: key => {
    dispatch(appActions.changeOpenKeys(key));
  },
  changeCurrent: key => {
    dispatch(appActions.changeCurrent(key));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Sidebar));
